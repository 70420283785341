@import 'tabulator-tables/dist/css/tabulator_bootstrap5.css';

.tabulator {
  @apply text-left mt-2 rounded-t-xl !border-l !border-r !border-gray-300 font-['Fustat',_sans-serif];
  /* The tailwind class is not applying borders to the left and right edges, it will be checked. */
  border-left: 0.070rem solid #d1d5db; border-right: 0.070rem solid #d1d5db;
}


/* loader */

.tabulator .tabulator-alert {
  @apply !bg-[#d3d1d166];
}

.tabulator .tabulator-alert .tabulator-alert-msg {
  @apply !bg-transparent !border-none;
}

.tabulator-spinner {
  @apply spinner w-12 h-12 animate-spin rounded-full border-2 border-indigo-700 border-r-transparent;
}

/* headers */

.tabulator .tabulator-header,
.tabulator .tabulator-header .tabulator-col {
  @apply bg-gray-100 text-sm text-slate-900 font-bold capitalize leading-normal justify-center;
}

.tabulator .tabulator-header .tabulator-col:not(.row-number) .tabulator-col-content {
  @apply flex items-center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  @apply whitespace-normal;
}

.tabulator .tabulator-header .row-number {
  @apply !text-base text-center text-slate-900 font-bold;
}

.tabulator-header .tabulator-header-filter input {
  @apply w-24 h-9 pl-2 pr-1 py-2 border border-slate-300 rounded-md !text-sm box-border placeholder:text-sm placeholder:pl-1;
}

.tabulator-header-search-icon {
  @apply absolute right-6 top-1/2 -translate-y-1/2;
}

/* sorter */

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter{
  @apply ml-2;
}

/* rows */

.tabulator .tabulator-table {
  @apply w-full;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row{
  @apply text-xs+ text-slate-900 leading-7;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-cell {
  @apply bg-gray-50 whitespace-normal content-center;
}

.tabulator .tabulator-row.tabulator-row-even .tabulator-cell {
  @apply bg-gray-100;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row .row-number {
  @apply !text-sm text-center text-slate-700 font-bold leading-7;
}

/* footer */

.tabulator .tabulator-footer {
  @apply bg-gray-50;
}

.tabulator-footer-contents {
  @apply relative flex w-full items-center flex-wrap sm:flex-nowrap;
}

.tabulator .tabulator-footer .tabulator-page-size {
  @apply text-xs+ font-semibold text-slate-700 form-select rounded-md border border-slate-300 bg-white px-2 py-1 pr-6 relative left-0 sm:absolute sm:left-4;
}

.tabulator .tabulator-footer .tabulator-paginator label {
  @apply hidden lg:block lg:absolute lg:left-20 mt-1 text-xs+ font-normal text-slate-700;
}

.tabulator .tabulator-footer .tabulator-page-counter {
  @apply text-xs+ text-slate-700 hidden xl:block xl:absolute xl:right-4;
}

.tabulator .tabulator-footer .tabulator-paginator {
  @apply text-center border-y border-gray-200 flex items-center justify-center w-full mt-2 sm:mt-0;
}

.tabulator .tabulator-footer .tabulator-page[data-page="first"],
.tabulator .tabulator-footer .tabulator-page[data-page="last"] {
  @apply !hidden;
}

.tabulator .tabulator-footer .tabulator-page {
  @apply -mt-px w-auto inline-flex items-center justify-center border-y-2;
}

.tabulator .tabulator-footer .tabulator-page.active {
  @apply bg-white border-y-2 border-indigo-500 px-4 pt-4 text-sm font-extrabold text-indigo-600 hover:bg-white hover:border-indigo-500;
}

.tabulator .tabulator-footer .tabulator-page[data-page="prev"],
.tabulator .tabulator-footer .tabulator-page[data-page="next"] {
  @apply flex w-auto items-center border-none pl-1 pt-4 mx-2 md:mx-4 text-sm font-bold text-slate-600 hover:text-slate-600 hover:bg-gray-50;
}

.tabulator .tabulator-footer .tabulator-page:not([data-page="prev"]):not([data-page="next"]):not(.active) {
  @apply border-y-2 border-transparent px-4 pt-4 text-sm font-extrabold text-slate-600 hover:text-indigo-600 hover:bg-gray-50 hover:border-gray-200 hidden md:inline-flex;
}