/*@todo missing tailwindcss classes starts with tw- */
.title {
    @apply uppercase text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl;
}
.card-title {
    @apply mb-4 uppercase text-base font-medium text-slate-700 dark:text-navy-100;
}
.tooltip-main-sidebar {
    @apply flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-navy-600 dark:text-accent-light dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90;
}
.validation-error-text {
    @apply text-tiny+ text-error mt-px;
}
.device-card-ts .ts-control {
    @apply rounded-l-none;
}
#datagrid > table > thead > tr > th > div > a.asc,
#datagrid > table > thead > tr > th > div > a.desc {
    @apply font-bold text-accent;
}
#datagrid > table > thead > tr > th.ordered.asc,
#datagrid > table > thead > tr > th.ordered.desc {
    @apply text-red-400;
}
#datagrid > table > thead > tr > th.ordered.desc > div.order > a.desc,
#datagrid > table > thead > tr > th.ordered.asc > div.order > a.asc {
    @apply text-red-400;
}
ul.list-reset li.active a {
    @apply bg-primary text-white;
}
.thead-bg-color {
    @apply bg-[#E2E8F0];
}

.leave-span {
    @apply pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
@media screen and (max-width: 1280px) {
    .line-clamp-2 {
        -webkit-line-clamp: 1;
    }
}
.table-link {
    @apply text-cyan-600 transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent underline;
}
.table-underline {
    @apply border border-transparent border-b-slate-200 dark:border-b-navy-500;
}
.custom-radio {
    @apply form-radio is-basic h-5 w-5 rounded-full border-slate-400/70 enabled:checked:border-primary enabled:checked:bg-primary enabled:hover:border-primary enabled:focus:border-primary disabled:bg-zinc-100 disabled:pointer-events-none;
}
.rotate90 {
    @apply transform rotate-90;
}
.rotate180 {
    @apply transform rotate-180;
}
.bg-whiteyellow {
    @apply !bg-[#FCF691];
}
.text-whiteyellow {
    @apply text-[#FCF691];
}
.tab-button {
    @apply flex px-4 py-2.5 space-x-2 tracking-wide transition-all outline-none rounded-lg;
}
.tab-button-default {
    @apply hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100;
}
.tab-button-active {
    @apply items-center bg-primary text-white dark:bg-accent;
}
.grouped-form-element {
    @apply p-2 bg-zinc-100;
}
.required-field {
    @apply flex items-center;
}
.required-field::after {
    @apply content-['*'] text-red-500 ml-1;
}
.tag-gray {
    @apply rounded-r-none bg-slate-150 text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-100 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90;
}
.tag-primary-number-field {
    @apply rounded-l-none bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90;
}
.tag-orange {
    @apply rounded-r-none border border-error text-error;
}
.tag-orange-number-field {
    @apply rounded-l-none bg-error text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90;
}

/*@todo temporary background color used by whole app (blue gray primary bg)*/
.app-bg-color {
    background-color: #f8fafc;
}

.button-primary-outlined {
    @apply btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90;
}

.button-secondary-outlined {
    @apply btn border border-secondary font-medium text-secondary hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white active:bg-secondary/90 dark:text-secondary-light dark:hover:bg-secondary dark:hover:text-white dark:focus:bg-secondary dark:focus:text-white dark:active:bg-secondary/90;
}

.button-secondary {
    @apply btn bg-secondary font-medium text-white hover:bg-secondary-focus focus:bg-secondary-focus active:bg-secondary-focus/90;
}

.ts-wrapper.disabled.locked > .ts-control {
    @apply bg-zinc-100 border-slate-300 hover:border-slate-400 focus:border-primary;
}

.field_with_errors {
    @apply contents;
}

.alert-success {
    @apply bg-green-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg;
}
.alert-success > svg {
    @apply text-green-600 w-5 h-5 sm:w-5 sm:h-5 mr-3;
}
.alert-success > span {
    @apply text-green-800;
}

.alert-error {
    @apply bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg;
}
.alert-error > svg {
    @apply text-red-600 w-5 h-5 sm:w-5 sm:h-5 mr-3;
}
.alert-error > span {
    @apply text-red-800;
}

.attachment-wrapper {
    @apply p-2 border border-gray-200 rounded-md relative;
}

.attachment-delete-file-btn {
    @apply absolute top-0 right-0 rounded-full bg-red-500 text-white cursor-pointer;
    transform: translate(50%, -50%);
    padding: 0 5px;
}

.custom-tooltip {
    @apply relative inline-block;
}

.custom-tooltip .custom-tooltiptext {
    @apply invisible w-[120px] bg-[#e9eef5] text-primary text-center rounded-md p-2 absolute z-50 top-full left-1/2 -ml-12 mt-2 opacity-0 transition-opacity duration-300;
}
  
.custom-tooltip:hover .custom-tooltiptext {
    @apply visible opacity-100;
}